import { gql } from '@apollo/client';

export const FormCategoryRead = gql`
	query CategoryRead_EditAndCreateForm($id: String) {
		categoryRead(id: $id) {
			id
			name
			description
			sequence
			type
			externalId
			markup
			clientDiscount
			location {
				id
				name
				address {
					line1
					line2
					city
					state
					country
					postalCode
				}
				gateway {
					id
				}
			}
			company {
				id
			}
			gateway {
				id
				external
				externalId
				active
				externalKey
			}
			items {
				id
				name
				image
				taxable
				isInventory
				uoms {
					id
					name
					price
				}
			}
			clients {
				id
				name
				email
			}
			itemsCount
			# Extra Details
			id
			createdAt
			updatedAt
		}
	}
`;
